.App {
  text-align: center;
  width: 640px;
  margin: 2rem auto;
  padding: 2rem;
  border-radius: 20px;
  box-shadow: 3px 3px 10px rgba(0, 0, 0, 0.2);
}

.complete {
  text-decoration: line-through;
}

input[type="text"] {
  padding: .5rem 1rem;
  background: #e9e9e9;
  border: none;
}

.add-btn {
  padding: .5rem 1rem;
  background: #287fcb;
  color: #fff;
  border: none;
}

.delete-btn {
  padding: .5rem 1rem;
  background: #cb2828;
  border-radius: 15px;
  color: #fff;
  border: none;
}

.complete-btn {
  padding: .5rem 1rem;
  background: #43cb28;
  border-radius: 15px;
  color: #fff;
  border: none;
}